import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9;
import React from "react";
var __jsx = React.createElement;
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { SafeAreaView, ScrollView, Pressable, View } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { useSelector } from 'react-redux';
import { useRouter } from 'solito/router';
import { isMultiBranch } from '~/api/models/ModelFunctions';
import { VisualMode } from '~/common/Env';
import Localize from '~/common/Localize';
import useBreakpoints from '~/common/hooks/useBreakpoints';
import useVisualMode from '~/common/hooks/useVisualMode';
import { FeatureFlagsEnum, selectFeatureFlag } from '~/common/slices/model.slice';
import { getRouteUrl, RouteName } from '~/navigation/Routes';
import LightInBox from '~/screens/home/components/LightInBox';
import SalonHero from '~/screens/home/components/SalonHero';
import { useCoursePurchase } from '~/screens/purchase/course/useCoursePurchase';
import { Body1, Container, Hero, Theme, themed, Title2, useAppTheme } from '~/styles/Theme';
import { BuyCoursesButton, EmailAddress, GalleryBanner, GrownByPhorest, MainActionButton, OpeningTimes, PhoneNumber, SalonAddress, Shop, Voucher } from './HomeComponents';
import { shouldDisplayGallery } from './HomeViewModel';
import ConsultationsBanner from './components/ConsultationsBanner';
import PastAppointmentBanner from './components/PastAppointmentBanner';
import SpecialOffersBanner from './components/SpecialOfferBanner';
var BASE_BUTTONS = 1; // Always present buttons

var HomeScreenNeonNights = function HomeScreenNeonNights(props) {
  var _branch$ecommerceSett, _branch$ecommerceSett2, _branch$ecommerceSett3, _branch$ecommerceSett4, _branch$onlineGiftCar, _branch$ecommerceSett5;
  var business = props.business,
    branch = props.branch,
    displayName = props.displayName,
    country = props.country,
    env = props.env,
    shouldHideOnlineStore = props.shouldHideOnlineStore;
  var _useBreakpoints = useBreakpoints(),
    isMobile = _useBreakpoints.isMobile;
  var theme = useAppTheme();
  var _useCoursePurchase = useCoursePurchase(),
    purchasableCourses = _useCoursePurchase.purchasableCourses;
  var shouldShowCoursePurchaseButton = !isEmpty(purchasableCourses);
  var shouldShowOnlineStoreButton = !shouldHideOnlineStore && ((_branch$ecommerceSett = branch === null || branch === void 0 || (_branch$ecommerceSett2 = branch.ecommerceSettings) === null || _branch$ecommerceSett2 === void 0 ? void 0 : _branch$ecommerceSett2.onlineStoreEnabled) !== null && _branch$ecommerceSett !== void 0 ? _branch$ecommerceSett : false) && ((_branch$ecommerceSett3 = branch === null || branch === void 0 || (_branch$ecommerceSett4 = branch.ecommerceSettings) === null || _branch$ecommerceSett4 === void 0 ? void 0 : _branch$ecommerceSett4.onlineStoreUrl) !== null && _branch$ecommerceSett3 !== void 0 ? _branch$ecommerceSett3 : false);
  var shouldShowVouchersButton = (_branch$onlineGiftCar = branch === null || branch === void 0 ? void 0 : branch.onlineGiftCardsEnabled) !== null && _branch$onlineGiftCar !== void 0 ? _branch$onlineGiftCar : false;
  var homeButtonsFlexBasis = useMemo(function () {
    var extraButtons = (shouldShowCoursePurchaseButton ? 1 : 0) + (shouldShowOnlineStoreButton ? 1 : 0) + (shouldShowVouchersButton ? 1 : 0);
    var totalButtons = BASE_BUTTONS + extraButtons;
    return totalButtons % 2 === 0 ? '50%' : "".concat(Math.floor(100 / totalButtons), "%");
  }, [shouldShowCoursePurchaseButton, shouldShowOnlineStoreButton, shouldShowVouchersButton]);
  return business && branch ? __jsx(View, {
    style: {
      flex: 1
    }
  }, __jsx(LightInBox, null), __jsx(BusinessAndBranchName, {
    business: business,
    branch: branch,
    displayName: displayName
  }), __jsx(ColoredDividerView, null), __jsx(ScrollView, null, __jsx(SalonHero, {
    url: theme.images.heroUrl,
    branch: branch
  }), __jsx(ItemContainer, null, __jsx(View, {
    style: {
      flex: 1,
      marginTop: -45
    }
  }, __jsx(View, {
    style: {
      flexDirection: isMobile ? 'column' : 'row',
      flexWrap: isMobile ? 'nowrap' : 'wrap',
      justifyContent: 'center'
    }
  }, __jsx(HomeButtonContainer, {
    flexBasis: homeButtonsFlexBasis
  }, __jsx(MainActionButton, {
    branch: branch
  })), shouldShowCoursePurchaseButton && __jsx(HomeButtonContainer, {
    flexBasis: homeButtonsFlexBasis
  }, __jsx(BuyCoursesButton, null)), shouldShowOnlineStoreButton && __jsx(HomeButtonContainer, {
    flexBasis: homeButtonsFlexBasis
  }, __jsx(Shop, {
    url: branch === null || branch === void 0 || (_branch$ecommerceSett5 = branch.ecommerceSettings) === null || _branch$ecommerceSett5 === void 0 ? void 0 : _branch$ecommerceSett5.onlineStoreUrl
  })), shouldShowVouchersButton && __jsx(HomeButtonContainer, {
    flexBasis: homeButtonsFlexBasis
  }, __jsx(Voucher, {
    branch: branch,
    country: country,
    env: env
  })), shouldDisplayGallery(business) ? __jsx(GalleryBanner, null) : null, __jsx(View, {
    style: {
      flex: 1
    }
  }, __jsx(View, {
    style: {
      flex: 0.5
    }
  }, __jsx(PastAppointmentBanner, {
    mainColor: "white"
  })), __jsx(View, {
    style: {
      flex: 0.5
    }
  }, __jsx(SpecialOffersBanner, null)), branch.leadManagementEnabled && __jsx(View, {
    style: {
      flex: 0.5
    }
  }, __jsx(ConsultationsBanner, null))), __jsx(AboutSalonContainer, null, __jsx(AboutSalonText, null, Localize('home.aboutSalon'))), __jsx(VerticalContainer, null, branch.phone ? __jsx(PhoneNumber, {
    phone: branch.phone,
    country: country,
    homePage: true,
    banner: false
  }) : null, __jsx(SalonAddress, {
    branch: branch,
    homePage: true,
    banner: false
  }), branch.email ? __jsx(EmailAddress, {
    email: branch.email,
    homePage: true,
    banner: false
  }) : null, __jsx(OpeningTimes, {
    branch: branch
  }))), __jsx(GrownByPhorest, null))))) : null;
};
var BusinessAndBranchName = function BusinessAndBranchName(props) {
  var business = props.business,
    branch = props.branch;
  var _useRouter = useRouter(),
    push = _useRouter.push;
  var theme = useAppTheme();
  var visualMode = useVisualMode();
  var locationSelectorEnabled = useSelector(selectFeatureFlag(FeatureFlagsEnum.LocationSelector));
  var salonName = props.displayName !== '' ? props.displayName : business.name;
  var isMultiBranched = isMultiBranch(business) && locationSelectorEnabled;
  var name = isMultiBranched ? salonName : branch.name;
  return __jsx(SafeAreaView, {
    style: {
      backgroundColor: '#02023E'
    }
  }, __jsx(HeaderContainer, {
    isScreenshot: visualMode === VisualMode.Screenshot
  }, __jsx(HeaderTitle, null, name), isMultiBranched ? __jsx(Pressable, {
    testID: 'branchNameButton',
    onPress: function onPress() {
      return push(getRouteUrl(RouteName.Locations));
    }
  }, __jsx(View, {
    style: {
      flexDirection: 'row',
      paddingLeft: 7
    }
  }, __jsx(SubHeaderTitle, null, branch.name), __jsx(View, {
    style: {
      paddingLeft: 3,
      paddingTop: 2
    }
  }, __jsx(Icon, {
    name: "chevron-down",
    color: theme.colors.homeScreen.icon,
    size: 18
  })))) : null));
};
export default HomeScreenNeonNights;
var ItemContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  justify-content: center;\n  backgroundColor: #02023E\n  paddingLeft: 20px\n  paddingRight: 20px\n  paddingTop: 20px\n  elevation: 2\n  shadow-color: gray\n  shadow-offset: 2px 2px\n  shadow-opacity: 1\n  shadow-radius: 4px\n  min-height: 70px\n  border-color: transparent\n"])));
var AboutSalonContainer = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding-top: 15px;\n"])));
var VerticalContainer = themed(Container)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  justify-content: center;\n"])));
var HeaderContainer = themed(Container)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  justify-content: center;\n  backgroundColor: #02023E\n  padding-bottom: 10px;\n  text-align: center;\n  alignItems: center;\n  ", "\n"])), function (props) {
  return "padding-top: ".concat(props.isScreenshot ? '40' : '15', "px;");
});
var HeaderTitle = themed(Hero)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  color: white;\n  padding: 5px;\n"])));
var SubHeaderTitle = themed(Body1)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  color: white;\n"])));
var AboutSalonText = themed(Title2)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  padding-bottom: 15px;\n  color: white;\n"])));
var ColoredDividerView = themed(Container)(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  border-width: 1.5px;\n  ", "\n"])), function (props) {
  return "border-color: ".concat(Theme(props).colors.main);
});
var HomeButtonContainer = themed(Container)(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  ", ";\n  padding: 4px;\n"])), function (_ref) {
  var flexBasis = _ref.flexBasis;
  return "flexBasis: ".concat(flexBasis);
});