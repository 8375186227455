import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
import React from "react";
var __jsx = React.createElement;
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { Pressable, ScrollView, View } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { useSelector } from 'react-redux';
import { useRouter } from 'solito/router';
import { isMultiBranch } from '~/api/models/ModelFunctions';
import { valueForNativeOnly, valueForPlatform } from '~/common/Helpers';
import Localize from '~/common/Localize';
import useBreakpoints from '~/common/hooks/useBreakpoints';
import { FeatureFlagsEnum, selectFeatureFlag } from '~/common/slices/model.slice';
import { RouteName, getRouteUrl } from '~/navigation/Routes';
import LightInBox from '~/screens/home/components/LightInBox';
import SalonHero from '~/screens/home/components/SalonHero';
import { useCoursePurchase } from '~/screens/purchase/course/useCoursePurchase';
import { Body2, Container, Hero, SubTitle2, Theme, Title2, themed, useAppTheme } from '~/styles/Theme';
import { BuyCoursesButton, GalleryBanner, GrownByPhorest, MainActionButton, Shop, Voucher } from './HomeComponents';
import { shouldDisplayGallery } from './HomeViewModel';
import ConsultationsBanner from './components/ConsultationsBanner';
import ContactInfo from './components/ContactInfo';
import PastAppointmentBanner from './components/PastAppointmentBanner';
import SpecialOffersBanner from './components/SpecialOfferBanner';
var BASE_BUTTONS = 1; // Always present buttons

export var HomeScreenFlora = function HomeScreenFlora(props) {
  var _branch$ecommerceSett, _branch$ecommerceSett2, _branch$ecommerceSett3, _branch$ecommerceSett4, _branch$onlineGiftCar, _branch$ecommerceSett5;
  var business = props.business,
    branch = props.branch,
    displayName = props.displayName,
    country = props.country,
    env = props.env,
    shouldHideOnlineStore = props.shouldHideOnlineStore;
  var _useBreakpoints = useBreakpoints(),
    isMobile = _useBreakpoints.isMobile;
  var theme = useAppTheme();
  var _useCoursePurchase = useCoursePurchase(),
    purchasableCourses = _useCoursePurchase.purchasableCourses;
  var shouldShowCoursePurchaseButton = !isEmpty(purchasableCourses);
  var shouldShowOnlineStoreButton = !shouldHideOnlineStore && ((_branch$ecommerceSett = branch === null || branch === void 0 || (_branch$ecommerceSett2 = branch.ecommerceSettings) === null || _branch$ecommerceSett2 === void 0 ? void 0 : _branch$ecommerceSett2.onlineStoreEnabled) !== null && _branch$ecommerceSett !== void 0 ? _branch$ecommerceSett : false) && ((_branch$ecommerceSett3 = branch === null || branch === void 0 || (_branch$ecommerceSett4 = branch.ecommerceSettings) === null || _branch$ecommerceSett4 === void 0 ? void 0 : _branch$ecommerceSett4.onlineStoreUrl) !== null && _branch$ecommerceSett3 !== void 0 ? _branch$ecommerceSett3 : false);
  var shouldShowVouchersButton = (_branch$onlineGiftCar = branch === null || branch === void 0 ? void 0 : branch.onlineGiftCardsEnabled) !== null && _branch$onlineGiftCar !== void 0 ? _branch$onlineGiftCar : false;
  var homeButtonsFlexBasis = useMemo(function () {
    var extraButtons = (shouldShowCoursePurchaseButton ? 1 : 0) + (shouldShowOnlineStoreButton ? 1 : 0) + (shouldShowVouchersButton ? 1 : 0);
    var totalButtons = BASE_BUTTONS + extraButtons;
    return totalButtons % 2 === 0 ? '50%' : "".concat(Math.floor(100 / totalButtons), "%");
  }, [shouldShowCoursePurchaseButton, shouldShowOnlineStoreButton, shouldShowVouchersButton]);
  return business && branch ? __jsx(View, {
    style: {
      flex: 1
    },
    testID: "homePageFlora"
  }, valueForNativeOnly(__jsx(LightInBox, null)), __jsx(ScrollView, {
    style: {
      backgroundColor: valueForPlatform({
        web: theme.colors.neutral05,
        "native": 'transparent'
      })
    }
  }, __jsx(SalonHero, {
    url: theme.images.heroUrl,
    branch: branch
  }), __jsx(ContentContainer, null, __jsx(MainContainer, null, __jsx(Body2, null, Localize('home.welcome')), __jsx(View, {
    style: {
      paddingBottom: 20
    }
  }, __jsx(BusinessAndBranchName, {
    business: business,
    branch: branch,
    displayName: displayName
  })), __jsx(View, {
    style: {
      flex: 1
    }
  }, __jsx(View, {
    style: {
      flexDirection: isMobile ? 'column' : 'row',
      flexWrap: isMobile ? 'nowrap' : 'wrap',
      justifyContent: 'center'
    }
  }, __jsx(HomeButtonContainer, {
    flexBasis: homeButtonsFlexBasis
  }, __jsx(MainActionButton, {
    branch: branch
  })), shouldShowCoursePurchaseButton && __jsx(HomeButtonContainer, {
    flexBasis: homeButtonsFlexBasis
  }, __jsx(BuyCoursesButton, null)), shouldShowOnlineStoreButton && __jsx(HomeButtonContainer, {
    flexBasis: homeButtonsFlexBasis
  }, __jsx(Shop, {
    url: branch === null || branch === void 0 || (_branch$ecommerceSett5 = branch.ecommerceSettings) === null || _branch$ecommerceSett5 === void 0 ? void 0 : _branch$ecommerceSett5.onlineStoreUrl
  })), shouldShowVouchersButton && __jsx(HomeButtonContainer, {
    flexBasis: homeButtonsFlexBasis
  }, __jsx(Voucher, {
    branch: branch,
    country: country,
    env: env
  }))), shouldDisplayGallery(business) ? __jsx(GalleryBanner, null) : null, __jsx(View, {
    style: {
      flex: 1
    }
  }, __jsx(View, {
    style: {
      flex: 0.5
    }
  }, __jsx(PastAppointmentBanner, {
    mainColor: "white"
  })), __jsx(View, {
    style: {
      flex: 0.5
    }
  }, __jsx(SpecialOffersBanner, null)), branch.leadManagementEnabled && __jsx(View, {
    style: {
      flex: 0.5
    }
  }, __jsx(ConsultationsBanner, null))), __jsx(AboutSalonContainer, null, __jsx(Title2, {
    style: {
      paddingBottom: 15
    }
  }, Localize('home.aboutSalon'))), __jsx(ContactInfo, null)), valueForNativeOnly(__jsx(GrownByPhorest, null)))))) : null;
};
var BusinessAndBranchName = function BusinessAndBranchName(props) {
  var business = props.business,
    branch = props.branch;
  var _useRouter = useRouter(),
    push = _useRouter.push;
  var theme = useAppTheme();
  var locationSelectorEnabled = useSelector(selectFeatureFlag(FeatureFlagsEnum.LocationSelector));
  var salonName = props.displayName !== '' ? props.displayName : business.name;
  var isMultiBranched = isMultiBranch(business) && locationSelectorEnabled;
  var name = isMultiBranched ? salonName : branch.name;
  return __jsx(View, null, __jsx(Hero, {
    testID: 'heroBranchName'
  }, name), isMultiBranched ? __jsx(BranchSelectorButton, {
    onPress: function onPress() {
      return push(getRouteUrl(RouteName.Locations));
    },
    testID: "branchNameButton"
  }, __jsx(Icon, {
    name: "map-pin",
    color: theme.colors.neutral7,
    size: 22,
    style: {
      marginRight: 8
    }
  }), __jsx(SubTitle2, null, branch === null || branch === void 0 ? void 0 : branch.name), __jsx(Icon, {
    name: "chevron-down",
    color: theme.colors.neutral4,
    size: 22,
    style: {
      marginLeft: 'auto'
    }
  })) : null);
};
export default HomeScreenFlora;
var MainContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  justify-content: center;\n  ", "\n  padding: 32px\n  marginTop: -20px\n  borderTopRightRadius: 24px\n  borderTopLeftRadius: 24px\n  min-height: 70px\n"])), function (props) {
  return "background-color: ".concat(Theme(props).colors.neutral05);
});
var AboutSalonContainer = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding-top: 15px;\n"])));
var ContentContainer = themed(Container)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  max-width: 1024px\n  width: 100%\n  margin: 0 auto\n"])));
var BranchSelectorButton = themed(Pressable)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  flex-direction: row\n  align-items: center\n  padding: 10px 16px\n  margin-top: 8px\n  border-width: 1px\n  ", "\n  border-style: solid\n  border-radius: 32px\n  ", "\n"])), function (props) {
  return "border-color: ".concat(Theme(props).colors.neutral2);
}, function (props) {
  return "background-color: ".concat(Theme(props).colors.neutral);
});
var HomeButtonContainer = themed(Container)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", ";\n  padding: 4px;\n"])), function (_ref) {
  var flexBasis = _ref.flexBasis;
  return "flexBasis: ".concat(flexBasis);
});